import Portfolio from '../Portfolio/Portfolio';

function App() {
    return (
        <>
            <Portfolio />
        </>
    );
}

export default App;
