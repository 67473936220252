import React from 'react';
import "./BackgroundTexture.scss";

const BackgroundTexture = () => {
    return (
        <div className='background-texture'>
            
        </div>
    );
};

export default BackgroundTexture;